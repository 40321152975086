exports.components = {
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-docker-template-js": () => import("./../../../src/templates/docker-template.js" /* webpackChunkName: "component---src-templates-docker-template-js" */),
  "component---src-templates-java-template-js": () => import("./../../../src/templates/java-template.js" /* webpackChunkName: "component---src-templates-java-template-js" */),
  "component---src-templates-jpa-template-js": () => import("./../../../src/templates/jpa-template.js" /* webpackChunkName: "component---src-templates-jpa-template-js" */),
  "component---src-templates-kubernetes-template-js": () => import("./../../../src/templates/kubernetes-template.js" /* webpackChunkName: "component---src-templates-kubernetes-template-js" */),
  "component---src-templates-other-devops-template-js": () => import("./../../../src/templates/other-devops-template.js" /* webpackChunkName: "component---src-templates-other-devops-template-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pattern-template-js": () => import("./../../../src/templates/pattern-template.js" /* webpackChunkName: "component---src-templates-pattern-template-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-spring-template-js": () => import("./../../../src/templates/spring-template.js" /* webpackChunkName: "component---src-templates-spring-template-js" */)
}

